import React, { Component } from 'react';
import * as cs from "./reconStream.module.css"
import Img1 from "../../../images/px-ReconCard.webp"
import Recon from "../../../images/recon.webp"

const ReconStream = () => {
    return (
        <div className={cs.mainn}>
            {/* <h1 className={cs.main_h} >About Us</h1> */}
            <div className={cs.wrapp} >

                <div className={cs.FlexContainer} >
                    <div className={cs.FlexItem1} >
                        <img src={Img1} className={cs.img_group} />
                        <div className={cs.btn_row} >
                        <div className={cs.hero_btn} ><a target="_blank" href={`reconSTREAM.pdf`} >Solution Details</a></div>
                    </div>
                    </div>

                    <div className={cs.FlexItem2} >
                    <img src={Recon} className={cs.img_title} />
                        <ul>
                            <li>Next Generation Modular "Pay as you go“, end-to-end reconciliation system
                                designed to perform automated reconciliations between varied
                                systems. reconSTREAM offers a single window operation for all types
                                of data reconciliations and can onboard any new type of reconciliation
                                using its unique workflow-based design in a cost and time effective
                                way</li>
                            <li>The product is designed from ground-up using highly scalable and
                                resilient technologies </li>
                            <li>Flexibility is considered in all aspects of the product design, meaning
                                it will be able to reconcile a wide variety of data sets from various
                                industries, including but not limited to Financial Services, Retail,
                                Government and Telecom</li>
                            <li>This solution delivers improved efficiency, reduces operational risk, and optimizes cost of operations
                            </li>
                            
                            <li>reconSTREAM is designed and developed to serve all businesses engaged in any B2B or B2C or P2P business transactions
                            </li>
                            
                        </ul>



                    </div>


                </div>
            </div>
        </div>
    );
};

export default ReconStream;