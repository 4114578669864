import React, { Component } from 'react';
import * as cs from "./oneStream.module.css"
import Img1 from "../../../images/px-OneCard.webp"
import Onestream from "../../../images/onestream.webp"

const OneStream = () => {
    return (
        <div className={cs.mainn}>
            {/* <h1 className={cs.main_h} >About Us</h1> */}
            <div className={cs.wrapp} >

                <div className={cs.FlexContainer} >
                    <div className={cs.FlexItem1} >
                        <img src={Img1} className={cs.img_group} />
                        <div className={cs.btn_row} >
                        <div className={cs.hero_btn} ><a target="_blank" href={`uniSTREAM.pdf`} >Solution Details</a></div>
                    </div>
                    </div>

                    <div className={cs.FlexItem2} >
                        <img src={Onestream} className={cs.img_title} />
                        
                        <ul>
                            <li>One System Providing A Cockpit View of Your Business In Realtime</li>
                            <li><strong>Customer Behavior</strong> - Accounts opening using which channel?</li>
                            <li><strong>KPIs</strong> - Top performing customer</li>
                            <li><strong>Touchpoints</strong> - Demographics</li>
                            <li><strong>Business Trends</strong> - Digital transactions & payments growth</li>
                            <li><strong>Focus on</strong> - Sales of products through channels</li>
                            <li><strong>Corporate Customers</strong> - Growth & behavior</li>
                            <li>Consumer Trends data</li>
                            <li>Cards Trends Data</li>
                            <li>Digital Trends & Growth</li>
                            <li>Single View of Cash, ATM, Branch & Retail</li>
                            <li>Services Performances</li>
                            <li>Customer Feed back</li>

                        {/* <h1>Hellow world</h1> */}
                        </ul>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default OneStream;