import * as React from "react"
import Solution_Details from "../components/Solution_Details/Solution_Details"
import SEO from "../components/gatsby/seo"

console.log(`${__dirname}/src/images2`)
const SolutionPage = ({ location }) => {
  
  return (
    <React.Fragment>
      <SEO title="Timestream Group"
        ogtitle="Timestream Group"
        description="Timestream Management and Solutions FZCO is a start-up software and services company registered in Dubai Free zone with the aim of providing quality industry agnostic digital solutions to enable organizations streamline operations and improve decision making."
        url="/solution/"
        image="https://timestreamgroup.com/timestream_icon.png" />
      <Solution_Details location={location} />
    </React.Fragment>
  )
}
//
export default SolutionPage


