import React, { Component, useEffect } from 'react';
import * as cs from "./Solution_Details.module.css"


import Img1 from "../../images/recon.webp"
import Img2 from "../../images/onestream.webp"
import Img_Back from "../../images/back.webp"
import Top from "../../images/top_arrow.png"


import ReconStream from './reconStream/reconStream';
import Footer from "../Footer/Footer"
import OneStream from './oneStream/OneStream';

import { navigate } from 'gatsby';
import Header from '../Header/Main_Header';

const Solution_Details = ({ location }) => {
    const [selected, setselected] = React.useState("ReconStream")
   
    useEffect(() => {
       
        if (location.state !== "undefined" && location.state) {
            setselected(location.state.select_option)
        }
    }, [])
    return (
        <><Header />
        <div className={cs.outer} >
            
            <div className={cs.back} onClick={()=>navigate("/")}  >
            <img src={Img_Back} className={cs.img_back} />
            <p>Back</p>
            </div>
            <div className={cs.main}>
                <h1>Solutions</h1>
                <div className={cs.wrap} >
                    <div className={cs.FlexContainer} >

                        <div className={cs.FlexItem}   data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease" data-aos-duration="1500"  
                        onClick={() => {
                            setselected("ReconStream")
                            window.scrollTo({ top: 450, behavior: 'smooth' })
                        }} >
                            <img src={Img1} className={cs.img_group} />
                        </div>

                        <div className={cs.FlexItem}   data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease" data-aos-duration="1500"  
                        onClick={() =>{
                             setselected("OneStream")
                             window.scrollTo({ top: 450, behavior: 'smooth' })
                        }} >
                            <img src={Img2} className={cs.img_group} />
                        </div>


                    </div>
                </div>
            </div>

            {selected == "ReconStream" ? <ReconStream /> : <OneStream />}

            <Footer />
                <div onClick={() =>
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })}
                    className="top" >
                    <img src={Top} style={{ width: 15, filter: "brightness(10)" }} />
                </div>
        </div>
        </>
    );
};

export default Solution_Details;